import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Toolbar,
  Pagination,
  InputBase,
  InputAdornment,
  Button,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Paper,
  Dialog,
  DialogContent,
  Typography,
  Stack,
  PaginationItem,
  Card,
  CardContent,
  LinearProgress,
  Drawer,
  Badge,
  TextField,
  Container,
  CardHeader,
} from "@mui/material";
import { PrimaryColor } from "../../component/theme/theme";
import SearchIcon from "@mui/icons-material/Search";
import { Tune, Cancel, Close, InfoOutlined, Image } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  StyledButtonContained,
  StyledButtonOutlined,
} from "../Admin/AccountCreation/accountCreation";
import axios from "axios";
import { formats } from "./../../global";
import RestoreForm from "./RestoreForm";
import { getLoginUser, getUserRole } from "./../../LocalStorageService";
import { getApiUrl } from "./../../global";
import { useFeatures } from "../FeaturesProvider/featuresProvider";
import { TAPES_ARRAY } from "../../utils/constants";
import { TapeDetails } from "../TapeDetails/tapeDetails";
import debounce from 'lodash/debounce';
import moment from 'moment';

const bottomDrawerHeight = "5rem";

const CustomFileTypeIcon = ({ color, path }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={path} />
    </svg>
  );
};

const videoIconPath =
  "M28.3333 17.5V11.6667C28.3333 11.2246 28.1577 10.8007 27.8452 10.4882C27.5326 10.1756 27.1087 10 26.6667 10H6.66667C6.22464 10 5.80072 10.1756 5.48816 10.4882C5.17559 10.8007 5 11.2246 5 11.6667V28.3333C5 28.7754 5.17559 29.1993 5.48816 29.5118C5.80072 29.8244 6.22464 30 6.66667 30H26.6667C27.1087 30 27.5326 29.8244 27.8452 29.5118C28.1577 29.1993 28.3333 28.7754 28.3333 28.3333V22.5L35 29.1667V10.8333L28.3333 17.5Z";
const spreadsheetIconPath =
  "M31.6667 18.3333V15H18.3333V8.33333H15V15H8.33333V18.3333H15V31.6667H18.3333V18.3333H31.6667ZM31.6667 5C32.5 5 33.3333 5.33333 33.9833 6.01667C34.6667 6.66667 35 7.5 35 8.33333V31.6667C35 32.5 34.6667 33.3333 33.9833 33.9833C33.3333 34.6667 32.5 35 31.6667 35H8.33333C7.5 35 6.66667 34.6667 6.01667 33.9833C5.33333 33.3333 5 32.5 5 31.6667V8.33333C5 7.5 5.33333 6.66667 6.01667 6.01667C6.66667 5.33333 7.5 5 8.33333 5H31.6667Z";
const documentIconPath =
  "M26.666 24.9993H14.9993V21.666H26.666V24.9993ZM31.666 18.3327H14.9993V14.9993H31.666V18.3327ZM31.666 11.666H14.9993V8.33268H31.666V11.666ZM4.99935 8.33268V34.9994H31.666V38.3327H4.99935C4.11529 38.3327 3.26745 37.9815 2.64233 37.3564C2.01721 36.7313 1.66602 35.8834 1.66602 34.9994V8.33268H4.99935ZM34.9994 1.66602C35.8834 1.66602 36.7313 2.01721 37.3564 2.64233C37.9815 3.26745 38.3327 4.11529 38.3327 4.99935V28.3327C38.3327 30.1827 36.8493 31.666 34.9994 31.666H11.666C10.782 31.666 9.93411 31.3148 9.30899 30.6897C8.68387 30.0646 8.33268 29.2167 8.33268 28.3327V4.99935C8.33268 3.14935 9.81602 1.66602 11.666 1.66602H34.9994ZM11.666 4.99935V28.3327H34.9994V4.99935H11.666Z";
const pdfIconPath =
  "M31.6667 5H8.33333C6.5 5 5 6.5 5 8.33333V31.6667C5 33.5 6.5 35 8.33333 35H31.6667C33.5 35 35 33.5 35 31.6667V8.33333C35 6.5 33.5 5 31.6667 5ZM15.8333 19.1667C15.8333 20.5 14.6667 21.6667 13.3333 21.6667H11.6667V25H9.16667V15H13.3333C14.6667 15 15.8333 16.1667 15.8333 17.5V19.1667ZM24.1667 22.5C24.1667 23.8333 23 25 21.6667 25H17.5V15H21.6667C23 15 24.1667 16.1667 24.1667 17.5V22.5ZM30.8333 17.5H28.3333V19.1667H30.8333V21.6667H28.3333V25H25.8333V15H30.8333V17.5ZM20 17.5H21.6667V22.5H20V17.5ZM11.6667 17.5H13.3333V19.1667H11.6667V17.5Z";
const imageIconPath =
  "M14.1667 22.5L18.3333 27.5L24.1667 20L31.6667 30H8.33333M35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5H8.33333C7.44928 5 6.60143 5.35119 5.97631 5.97631C5.35119 6.60143 5 7.44928 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667Z";

const restoreTheme = createTheme({
  palette: {
    primary: {
      main: "#FFC540",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedSizeMedium: {
          width: "180px",
          height: "60px",
          borderRadius: "30px",
        },
        containedPrimary: {
          background: "linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)",
          color: "#3A3E3F",
        },
        containedSecondary: {
          background: "#3A3E3F",
          color: "#FFC540",
          border: "1px solid #FFC540",
          "&:hover": {
            backgroundColor: "rgba(255, 197, 64, 0.1)",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          background: "transparent",
          color: "#FFFFFF",
          "&.Mui-selected": {
            backgroundColor: "#FFC540",
            color: "#3E3A3F",
            "&:hover": {
              backgroundColor: "#FFC540",
              color: "#3E3A3F",
            },
            "&.file-type": {
              border: "1px solid #FFC540",
              background: "rgba(255, 197, 64, 0.1)",
              color: "#FFC540",
            },
          },
          "&.display-mode": {
            fontSize: "16px",
            fontWeight: 700,
            color: "#FFFFFF",
            height: 50,
            width: 140,
            fontSize: 12,
          },
          "&.file-type": {
            border: "1px solid #FFFFFF",
            width: "160px",
            height: "70px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            color: "#FFFFFF",
          },
          "& svg": {
            color: "#FFFFFF",
          },
          "&.number-input": {
            color: "#FFFFFF",
            width: "20%",
            "& fieldset": {
              borderRadius: "8px 0px 0px 8px",
              border: "1px solid #FFFFFF",
            },
            "&:hover fieldset": {
              borderColor: "#FFC540",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFC540",
            },
            "& .MuiInputLabel-root": {
              color: "rgba(255, 255, 255, 0.5)",
              "&.Mui-focused": {
                color: "#FFC540",
              },
            },
          },
          "&.number-input-uom": {
            width: "20%",
            "& fieldset": {
              border: "1px solid #FFFFFF",
              borderRadius: "0px 8px 8px 0px",
            },
            "&:hover fieldset": {
              borderColor: "#FFC540",
            },
          },
          "&.date-input": {
            color: "#FFFFFF",
            width: "85%",
            "& fieldset": {
              width: "100%",
              borderRadius: "8px",
              border: "1px solid #FFFFFF",
            },
            "&:hover fieldset": {
              borderColor: "#FFC540",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#FFC540",
            },
            "& .MuiInputLabel-root": {
              color: "#FFFFFF",
              "&.Mui-focused": {
                color: "#FFC540",
              },
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #5F5F5F",
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        },
      },
    },
  },
});
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "" : "#FFC540",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      //color: '#33cf4d',
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
    color: "#FFFFFF",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "" : "#FFC540",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles({
  rootMenuItem: {
    "&$selected": {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "green",
        borderRadius: 12,
        border: "1px solid var(--White, #FFF)",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      border: 0,
      borderRadius: 12,
      border: "1px solid var(--White, #FFF)",
    },
  },
  highlight: {
    background: "#FFC540",
    color: "#3A3E3F",
  },
  checkedColr: {
    "&.Mui-checked": {
      color: "red",
    },
  },
  selectLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        borderColor: "#C7CCD0",
      },
      "&:hover fieldset": {
        borderColor: "#C7CCD0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#C7CCD0",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root": {
      color: "rgba(255, 255, 255, 0.5)",
      "&.Mui-focused": {
        color: "#FFC540",
      },
    },
    "& svg": {
      color: "#FFFFFF",
    },
  },
  checkedColr: {
    "&.Mui-checked": {
      color: "red",
    },
  },
});

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3A3E3F",
    color: "#F9F9F9",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&.MuiTableRow-root": {
    "& > *": {
      color: "#FFFFFF",
      backgroundColor: "#3A3E3F",
      border: "20px solid #FFFFFF1A",
      borderRadius: "8px",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const dateOptions = { year: "numeric", month: "short", day: "numeric" };
const docType = {
  TEXT_DOCUMENT: "Document",
  SPREADSHEET: "Spreadsheet",
  VIDEO: "Video",
  IMAGE: "Image",
  PDF: "PDF",
};

const docTypeOverview = [
  {
    docType: docType.TEXT_DOCUMENT,
    iconPath: documentIconPath,
    count: 355759,
    size: "455.11 TB",
    selected: false,
  },
  {
    docType: docType.PDF,
    iconPath: pdfIconPath,
    count: 145442,
    size: "3.12 TB",
    selected: false,
  },
  {
    docType: docType.VIDEO,
    iconPath: videoIconPath,
    count: 511285,
    size: "1203 PB",
    selected: false,
  },
  {
    docType: docType.IMAGE,
    iconPath: imageIconPath,
    count: 224145,
    size: "480.64 TB",
    selected: false,
  },
  {
    docType: docType.SPREADSHEET,
    iconPath: spreadsheetIconPath,
    count: 12409,
    size: "26.11 TB",
    selected: false,
  },
];

export const Restore = () => {
  const [listFilter, setListFilter] = React.useState("tapeList");
  const [selectedData, setSelectedData] = React.useState();
  const [openTapeDetails, setOpenTapeDetails] = React.useState(false);
  const [fileTypeFilter, setFileTypeFilter] = useState(() => []);
  const isFileTypeFilterSelected = (fileType) => {
    return fileTypeFilter.find((type) => type === fileType);
  };
  const [allRestoreList, setAllRestoreList] = useState([]);
  const features = useFeatures()
  const [restoreData, setRestoreData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalFetched, setTotalFetched] = useState(false);
  useEffect(() => {
    if (features && features?.features?.find((feature) => feature === 'admin_dashboard')) {
      setIsAdmin(true);
      if (!totalFetched) {
        // getRestoreData(true)
        getItemCount(true);
        setTotalFetched(true);
      }
    } else if (features && features?.features?.find((feature) => feature !== 'admin_dashboard')) {
      setIsAdmin(false);
      if (!totalFetched) {
        // getRestoreData(false)
        getItemCount(false);
        setTotalFetched(true);
      }
    }
  }, [features])
  const clearRestoreTypeSelection = () => {
    setPhysicalshow(false);
    setCloudshow(false);
  }
  const formatBytes = (bytes, decimals = 0) => {
    if (!+bytes) return '0'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  const functionDebounce = useCallback(
    debounce((e, _listFilter) => {
      if (_listFilter == 'tapeList') {
        getItemCount(isAdmin, e?.target?.value);
      } else if (_listFilter == 'itemList') {
        getFileCount(isAdmin, e?.target?.value);
      }
    }, 500),
    []
  )
  // man_item_count
  const getFileCount = (_isAdmin, search = "") => {

    let payload = {
      type: "man_search_file_count",
      request: {
        cid: 15,
        type: "man_search_file_count",
        // page_number: 1,
        // page_size: 10,
        search: search ? search : undefined
      },
    };
    axios
      .post(formats, payload)
      .then((response) => {
        if (response?.status == 200) {
          let _count = response?.data?.result?.data?.count ? response?.data?.result?.data?.count : 0;
          setTotalPages(Math.ceil(_count / 10));
          setTapeCount(_count);
          getFileData(_isAdmin, 1, payload.request.search);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getFileData = (_isAdmin, pageNumber, search = "") => {
    let payload = {
      type: "man_search_file",
      request: {
        cid: 15,
        type: "man_search_file",
        page_number: pageNumber - 1,
        page_size: 10,
        search: search ? search : undefined
      },
    };
    axios
      .post(formats, payload)
      .then((response) => {
        if (response?.status == 200) {
          let dynamicArray;
          let dynamicObject;
          if (!_isAdmin) {
            dynamicObject = Object.values(response.data.result)
            dynamicObject.forEach(i =>
              dynamicArray = i.data)
          } else {
            dynamicArray = [];
            dynamicObject = Object.values(response.data.result)
            dynamicObject[1].forEach(i => dynamicArray.push(i))
          }
          dynamicArray.forEach((e) => {
            e.item_id = e?.file_id;
            e.item_volser = e?.volsers?.join(", ");
            e.media_type = e?.file_types?.join(", ");
            e.backup_format = e?.file_groups?.join(", ");
            e.size = formatBytes(e?.size);
            e.retentionDate = e?.backed_up;
            e.createdOn = e?.created;
          })
          setAllRestoreList(dynamicArray);
          setRestoreData(dynamicArray.slice(0, 10));
          mapAddedRows(dynamicArray.slice(0, 10));
          // setTotalPages(Math.ceil(dynamicArray.length / 10));
          // setTapeCount(dynamicArray.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // man_item_count
  const getItemCount = (_isAdmin, search = "") => {
    let payload = {
      type: "man_item_count",
      request: {
        cid: 15,
        type: "man_item_count",
        search: search ? search : undefined
        // page_number: 1,
        // page_size: 10
      },
    };
    axios
      .post(formats, payload)
      .then((response) => {
        if (response?.status == 200) {
          let _count = response?.data?.result?.data?.count ? response?.data?.result?.data?.count : 0;
          setTotalPages(Math.ceil(_count / 10));
          setTapeCount(_count);
          getRestoreData(_isAdmin, 1, payload.request.search);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getRestoreData = (_isAdmin, pageNumber, search = "") => {
    let payload = {
      type: "man_item_list",
      request: {
        cid: 15,
        type: "man_item_list",
        page_number: pageNumber - 1,
        page_size: 10,
        search: search ? search : undefined
      },
    };
    axios
      .post(formats, payload)
      .then((response) => {
        if (response?.status == 200) {
          let dynamicArray;
          let dynamicObject;
          if (!_isAdmin) {
            dynamicObject = Object.values(response.data.result)
            dynamicObject.forEach(i =>
              dynamicArray = i.data)
          } else {
            dynamicArray = [];
            dynamicObject = Object.values(response.data.result)
            dynamicObject[1].forEach(i => dynamicArray.push(i))
          }
          dynamicArray.forEach((e) => {
            e.createdOn = moment(e?.created).format('DD/MMM/YYYY');
            e.retentionDate = moment(e?.retention_date).format('DD/MMM/YYYY');
            e.size = formatBytes(e?.data_volume);
          })
          setAllRestoreList(dynamicArray);
          setRestoreData(dynamicArray.slice(0, 10));
          mapAddedRows(dynamicArray.slice(0, 10));
          // setTotalPages(Math.ceil(dynamicArray.length / 10));
          // setTapeCount(dynamicArray.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [addedRows, setAddedRows] = useState([]);
  const handleAddRow = (row) => {
    const newRestoreData = [...restoreData];
    newRestoreData.find(
      (newDataRow) => newDataRow.item_id === row.item_id
    ).selected = true;
    setRestoreData(newRestoreData);
    setAddedRows([...addedRows, row]);
  };
  const mapAddedRows = (_restoreData) => {
    const newRestoreData = [..._restoreData];
    addedRows.forEach((e) => {
      let selectedRecord = newRestoreData.find(
        (newDataRow) => newDataRow.item_id === e.item_id
      );
      if (selectedRecord) {
        newRestoreData.find(
          (newDataRow) => newDataRow.item_id === e.item_id
        ).selected = true;
      }
    })
    setRestoreData(newRestoreData);
  }
  const handleRemoveRow = (id) => {
    const newRestoreData = [...restoreData];
    let selectedData = newRestoreData.find((row) => row?.item_id === id);
    if (selectedData) {
      newRestoreData.find((row) => row?.item_id === id).selected = false;
      setRestoreData(newRestoreData);
    }
    const updateAddedRows = addedRows.filter((row) => row.item_id !== id);
    setAddedRows(updateAddedRows);
  };

  const clearAddedRows = () => {
    const newRestoreData = [...restoreData];
    newRestoreData.forEach((row) => (row.selected = false));
    setRestoreData(newRestoreData);
    setAddedRows([]);
  };

  const classes = useStyles();
  // const totalPages = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tapeCount, setTapeCount] = useState(0);

  const [sortBy, setSortBy] = useState("columnName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [checkoutDialog, setCheckoutDialog] = React.useState(false);
  const [showadd, setShowadd] = React.useState([
    {
      id: "",
      show: true,
    },
  ]);
  const [addcount, setaddCount] = React.useState(0);
  const [selectedTape, setSelectedTape] = React.useState([
    {
      id: "",
      name: "",
      tape: "",
    },
  ]);

  const handleContinue = () => {
    if (physicalshow) {
      setCheckoutDialogOpen(false);
      setAdressDialog(true);
      // setCheckoutDialog(true);
    } else {
      setCheckoutDialogOpen(false);
      setCheckoutDialog(true);
    }
  };

  const handleClickcancel = () => {
    //
    if (physicalshow) {
      setCheckoutDialog(false);
      // setAdressDialog(true);
    } else {
      setCheckoutDialog(false);
      setCheckoutDialogOpen(true);
    }
  };
  const [id, setId] = React.useState();
  const onClickAdd = (id, name, tape) => {
    setId(id);
    setSelectedTape([
      ...selectedTape,
      {
        id: id,
        name: name,
        tape: tape,
      },
    ]);
    setaddCount(addcount + 1);
    setShowadd(false);
  };
  const onclickRemove = (id, name, tape) => {
    const data = [...selectedTape];
    data.splice(id, 1);
    setSelectedTape(data);
    setId(id);
    setShowadd(true);
  };
  const [cloudshow, setCloudshow] = React.useState(false);
  const [physicalshow, setPhysicalshow] = React.useState(false);
  const [addressDialog, setAdressDialog] = React.useState(false);
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const onChangeCloud = (e) => {
    e.target.checked ? setCloudshow(true) : setCloudshow(false);
  };
  const onChangephysical = (e) => {
    e.target.checked ? setPhysicalshow(true) : setPhysicalshow(false);
  };
  console.log(selectedTape);
  // Filter Popup
  const [filterPopupOpen, setFilterPopupOpen] = React.useState(false);
  // To open the filter popup
  const handleOpenFilterPopup = () => {
    setFilterPopupOpen(true);
  };
  // To close the filter popup
  const handleCloseFilterPopup = () => {
    setFilterPopupOpen(false);
  };

  // Checkout Dialog
  const [checkoutDialogOpen, setCheckoutDialogOpen] = useState(false);
  // To open checkout dialog
  const handleOpenCheckoutDialog = () => {
    setCheckoutDialogOpen(true);
  };
  // To close checkout dialog
  const handleCloseCheckoutDialog = () => {
    setCheckoutDialogOpen(false);
  };

  // Form Dialog
  const [isFormOpen, setIsFormOpen] = useState(false);
  // To open form dialog
  const handleOpenFormDialog = () => {
    setIsFormOpen(true);
  };
  // To close form dialog
  const handleCloseFormDialog = () => {
    setIsFormOpen(false);
  };

  // Restores List display mode
  const [displayMode, setDisplayMode] = useState("All");
  const handleDisplayModeChange = (event, mode) => {
    if (mode !== null) {
      setDisplayMode(mode);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    // let _slice = allRestoreList.slice(
    //   (newPage - 1) * 10,
    //   (newPage - 1) * 10 + 10
    // );
    if (listFilter == 'tapeList') {
      getRestoreData(isAdmin, newPage, searchQuery);
    } else {
      getFileData(isAdmin, newPage, searchQuery);
    }
    // setRestoreData(_slice);
  };

  const startIndex = (currentPage - 1) * 10 + 1;
  const endIndex = Math.min(currentPage * 10, tapeCount);

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };
  const handlecloseAddress = () => {
    setCheckoutDialogOpen(true);
    setAdressDialog(false);
  };
  const handleaddress = () => {
    setCheckoutDialog(true);
    setAdressDialog(false);
  };

  const showReset = () => {
    setPhysicalshow(false)
    setCloudshow(false)
  }
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchQuery = (event) => {
    functionDebounce(event, listFilter);
    setSearchQuery(event.target.value);
  };

  const highlightText = (id, text) => {
    if (!searchQuery || searchQuery === "") {
      return text;
    }
    const regex = new RegExp(`(${searchQuery})`, "gi");
    const parts = text?.split(regex);
    return parts?.map((part, index) =>
      part?.toLowerCase() === searchQuery.toLowerCase() ? (
        <Typography sx={{ fontSize: 14 }} className={classes.highlight}>
          {part}
        </Typography>
      ) : (
        <Typography sx={{ fontSize: 14 }}>{part}</Typography>
      )
    );
  };
  const getTapeImage = (media_type) => {
    let _data = TAPES_ARRAY.filter(e => e.fileName.includes(media_type));
    return _data?.length ? _data[0].filePath : TAPES_ARRAY[TAPES_ARRAY.length]?.filePath;
  }
  const [filterCount, setFilterCount] = useState(0);
  const selectDetail = (row) => {
    setSelectedData(row.item_id);
    setOpenTapeDetails(true);
  }
  const listFilterChange = (e) => {
    setListFilter(e.target.value);
    setSearchQuery("");
    clearAddedRows();
    setCurrentPage(1);
    if (e.target.value == 'tapeList') {
      getItemCount(isAdmin);
    } else if (e.target.value == 'itemList') {
      getFileCount(isAdmin);
    }
  }
  const clearSearch = () => {
    setSearchQuery("");
    if (listFilter == 'tapeList') {
      getItemCount(isAdmin, "");
    } else if (listFilter == 'itemList') {
      getFileCount(isAdmin, "");
    }
  }
  return (
    <>
      <ThemeProvider theme={restoreTheme}>
        <Dialog
          open={checkoutDialog}
          fullWidth={true}
          PaperProps={{
            sx: { borderRadius: "12px", backgroundColor: "#3A3E3F" },
          }}
        >
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "36px",
                    color: "#FFC540",
                  }}
                >
                  Checkout
                </Typography>
              </Grid>
              <Grid item>
                <Typography style={{ color: "#FFFFFF" }}>
                  <b>{addedRows.length} items</b>
                </Typography>
              </Grid>
              <hr style={{ backgroundColor: "#5F5F5F" }} />
              <Grid item>
                <Typography style={{ color: "#FFFFFF" }}>
                  <b>Name</b>
                </Typography>
              </Grid>
              {addedRows.map((i, t) => {
                return (
                  <>
                    {i.selected && (
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            {/* <Typography style={{ color: "#FFFFFF" }}>
                              {i.job}
                            </Typography> */}
			    { listFilter == 'tapeList' ? (
                            <Typography style={{ color: "#FFFFFF" }}>
			      Tape type: <b>{i.media_type}</b>
                            </Typography> )
			    : (
                            <Typography style={{ color: "#FFFFFF" }}>
			      Filename: <b>{i.name}</b>
                            </Typography> )
			    }
                            <Typography style={{ color: "#FFFFFF" }}>
                              Volser: <b>{i.item_volser}</b>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <StyledButtonOutlined
                              variant="outlined"
                              onClick={() => handleRemoveRow(i.item_id)}
                              sx={{
                                width: "80px",
                                height: "40px",
                              }}
                            >
                              Remove
                            </StyledButtonOutlined>
                          </Grid>
                        </Grid>
                        <hr
                          style={{
                            backgroundColor: "#5F5F5F",
                            marginTop: "10px",
                          }}
                        />
                      </Grid>
                    )}
                  </>
                );
              })}
              <Grid item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <StyledButtonOutlined
                      variant="outlined"
                      onClick={handleClickcancel}
                      sx={{
                        width: "200px",
                        height: "60px",
                      }}
                    >
                      Cancel
                    </StyledButtonOutlined>
                  </Grid>
                  <StyledButtonContained
                    variant="contained"
                    sx={{
                      width: "200px",
                      height: "60px",
                      borderRadius: "30px",
                    }}
                    onClick={handleOpenFormDialog}
                  >
                    Continue
                  </StyledButtonContained>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          open={checkoutDialogOpen}
          fullWidth={true}
          PaperProps={{
            sx: { borderRadius: "12px", backgroundColor: "#3A3E3F" },
          }}
        >
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item style={{ marginBottom: "12px" }}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography style={{ color: "white" }}>
                      How do you want your files?
                    </Typography>
                  </Grid>
                  <Box mt={-2} mr={-2} ml={2}>
                    <Grid item>
                      <CloseIcon
                        onClick={handleCloseCheckoutDialog}
                        style={{ cursor: "pointer", color: "white" }}
                        color="white"
                        fontSize="small"
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  border: "1px solid white",
                  borderRadius: "12px",
                  marginBottom: "12px",
                }}
              >
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography style={{ color: "white" }}>
                      {" "}
                      Cloud restore
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box mb={2} mr={1}>
                      <IOSSwitch
                        checked={cloudshow}
                        value="cloud"
                        color="default"
                        onChange={onChangeCloud}
                      />
                    </Box>
                    {/* <Switch checked={cloudshow} value='cloud' color='default' onChange={onChangeCloud} /> */}
                  </Grid>
                </Grid>
                {cloudshow ? (
                  <>
                    <Grid item>
                      <Grid container direction="row" justifyContent="flex-start">
                        <Box>
                          <Grid item mt={-1}>
                            <Checkbox size="small" />
                          </Grid>
                        </Box>
                        <Grid item>
                          <Typography style={{ color: "white" }}>
                            I want to have a signed URL for my files
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="flex-start">
                        <Box>
                          <Grid item mt={-1}>
                            <Checkbox size="small" />
                          </Grid>
                        </Box>
                        <Grid item>
                          <Typography style={{ color: "white" }}>
                            I want the files to be accessible for a specific time.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="flex-start">
                        <Box>
                          <Grid item mt={-1}>
                            <Checkbox size="small" />
                          </Grid>
                        </Box>
                        <Grid item>
                          <Typography style={{ color: "white" }}>
                            I want the files to be put into the folder of tape
                            names they belong to.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid
                item
                style={{ border: "1px solid white", borderRadius: "12px" }}
              >
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography style={{ color: "white" }}>
                      Physical restore
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box mb={2} mr={1}>
                      <IOSSwitch
                        checked={physicalshow}
                        value="physical"
                        color="default"
                        onChange={onChangephysical}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {physicalshow ? (
                  <>
                    <Grid item>
                      <Grid container direction="row" justifyContent="flex-start">
                        <Box>
                          <Grid item mt={-1}>
                            <Checkbox size="small" />
                          </Grid>
                        </Box>
                        <Grid item>
                          <Typography style={{ color: "white" }}>
                            I want the disk to be sent to a different addresses
                            than the source
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="flex-start">
                        <Box>
                          <Grid item mt={-1}>
                            <Checkbox size="small" />
                          </Grid>
                        </Box>
                        <Grid item>
                          <Typography style={{ color: "white" }}>
                            I want to choose the disk type on which the
                            files are sent
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" justifyContent="flex-start">
                        <Box>
                          <Grid item mt={-1}>
                            <Checkbox size="small" />
                          </Grid>
                        </Box>
                        <Grid item>
                          <Typography style={{ color: "white" }}>
                            I want the disk to have encryption enabled by default
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <StyledButtonOutlined
                      variant="outlined"
                      sx={{
                        width: "200px",
                        height: "60px",
                      }}
                      onClick={clearRestoreTypeSelection}
                    >
                      Clear
                    </StyledButtonOutlined>
                  </Grid>
                  <Grid item>
                    <StyledButtonContained
                      onClick={handleaddress}
                      variant="contained"
                      sx={{
                        width: "200px",
                        height: "60px",
                      }}
                    >
                      Continue
                    </StyledButtonContained>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <Dialog
          open={addressDialog}
          fullWidth={true}
          PaperProps={{
            sx: { borderRadius: "12px", backgroundColor: "#3A3E3F" },
          }}
        >
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "24px",
                        fontWeight: 400,
                      }}
                    >
                      Select Address
                    </Typography>
                  </Grid>
                  <Box mt={-2} mr={-2} ml={2}>
                    <Grid item>
                      <CloseIcon
                        onClick={handlecloseAddress}
                        style={{ cursor: "pointer", color: "white" }}
                        color="white"
                        fontSize="small"
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid item className={classes.selectLabel}>
                <TextField
                  fullWidth
                  label="Enter Zip Code"
                  id="fullWidth"
                  sx={{ label: { color: "white" } }}
                />
              </Grid>
              {/* <Grid item> */}
              {/* <FormControl fullWidth={true}>
                  <TextField
                    className={classes.selectLabel}
                    value={country}
                    label="Select Country"
                    onChange={(e) => setCountry(e.target.value)}
                    fullWidth={true}
                    select
                  >
                    <MenuItem>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth={true}>
                  <TextField
                    className={classes.selectLabel}
                    fullWidth
                    value={state}
                    label="Select State"
                    onChange={(e) => setState(e.target.value)}
                    select
                  >
                    <MenuItem>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </TextField>
                </FormControl> */}
              {/* </Grid> */}
              <Grid item className={classes.selectLabel}>
                <TextField
                  fullWidth
                  label="Enter Mobile Number"
                  id="fullWidth"
                  sx={{ label: { color: "white" } }}
                />
              </Grid>
              <Grid item className={classes.selectLabel}>
                <TextField
                  fullWidth
                  label="Address line 1"
                  id="fullWidth"
                  sx={{ label: { color: "white" } }}
                />
              </Grid>
              <Grid item className={classes.selectLabel}>
                <TextField
                  fullWidth
                  label="Address line 2"
                  id="fullWidth"
                  sx={{ label: { color: "white" } }}
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-end">
                  <Grid item>
                    <StyledButtonContained
                      variant="contained"
                      onClick={() => {
                        handleaddress();
                      }}
                    >
                      Save & Continue
                    </StyledButtonContained>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        {/* // Restore Form Component */}
        <RestoreForm
          isFormOpen={isFormOpen}
          handleCloseFormDialog={handleCloseFormDialog}
          cloudShow={cloudshow}
          physicalShow={physicalshow}
          rowData={addedRows}
          handleCloseCheckoutDialog={handleCloseCheckoutDialog}
          handleClickcancel={handleClickcancel}
          clearAddedRows={clearAddedRows}
          showReset={showReset}
          listFilter={listFilter}
        />
        {/* // Restore Form Component */}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            paddingTop: 4,
            paddingLeft: 4,
          }}
          className="hideElement"
        >
          <img
            src="/assets/img/restore.png"
            alt=""
            style={{ filter: "brightness(10)" }}
          />
        </Box>

        {/* Header */}
        <Grid
          container
          spacing={2}
          paddingBottom={6}
          sx={{ paddingX: { xs: 0.5, md: 4 } }}
          className="hideElement"
        >
          <Grid item md={12}>
            <Typography
              variant="h5"
              mt={4}
              sx={{
                fontFamily: "Lato",
                fontSize: "28px",
                fontWeight: 700,
                lineHeight: "34px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              Restore Files
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Divider
              sx={{ border: "1px solid #EAEAEA", width: "100%" }}
              orientation="horizontal"
            />
          </Grid>
        </Grid>

        <Grid
          container
          px={8}
          spacing={2}
          paddingBottom={6}
          sx={{ paddingX: { xs: 0.5, md: 4 } }}
          mb={bottomDrawerHeight}
        >
          <Grid item xs={12}>
            {/* Overview Filters */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={4}
            >
              <Grid item md={12}>
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "#FFFFFF",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: 700,
                  }}
                  style={{ fontWeight: 700, fontSize: '20px', color: "#fff" }}
                >
                  Overview
                </Typography>
              </Grid>
              {docTypeOverview.map((item, _index) => (
                <Grid item md key={_index} minWidth={"20%"}>
                  <Card
                    sx={{
                      backgroundColor: isFileTypeFilterSelected(item.docType)
                        ? "#FFC540"
                        : "#3A3E3F",
                      border: "1px solid #C7CCD0",
                      borderRadius: "12px",
                      height: "120px",
                      textAlign: "left",
                    }}
                  >
                    <CardHeader
                      sx={{
                        pb: 0,
                        "& .MuiCardHeader-title": {
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          fontSize: 18,
                          color: isFileTypeFilterSelected(item.docType)
                            ? "#3A3E3F"
                            : "#FFFFFF",
                        },
                        "& .MuiCardHeader-subheader": {
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          fontSize: 12,
                          color: isFileTypeFilterSelected(item.docType)
                            ? "#3A3E3F"
                            : "#C7CCD0",
                        },
                      }}
                      title={item.docType}
                      subheader={"2,164 items"}
                      avatar={
                        <CustomFileTypeIcon
                          color={
                            isFileTypeFilterSelected(item.docType)
                              ? "#3A3E3F"
                              : "#FFC540"
                          }
                          path={item.iconPath}
                        />
                      }
                    // action={
                    //   <IconButton>
                    //     <InfoOutlined
                    //       sx={{
                    //         color: isFileTypeFilterSelected(item.docType)
                    //           ? "#3A3E3F"
                    //           : "#FFC540",
                    //       }}
                    //     />
                    //   </IconButton>
                    // }
                    />
                    <CardContent>
                      <LinearProgress
                        variant="determinate"
                        value={70}
                        sx={{
                          backgroundColor: "#3A3E3F",
                          height: 10,
                          borderRadius: 8,
                          border: isFileTypeFilterSelected(item.docType)
                            ? "1px solid #3A3E3F"
                            : "1px solid #FFC540",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#FFC540",
                          },
                        }}
                      />
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          fontSize: "12px",
                          lineHeight: "18px",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          color: isFileTypeFilterSelected(item.docType)
                            ? "#3A3E3F"
                            : "#C7CCD0",
                          mt: 1,
                        }}
                      >
                        Total {item.size}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer
              sx={{
                mt: 8,
                maxWidth: "100%",
                border: "1px solid #C7CCD0",
                borderRadius: "12px 12px 0px 0px",
                minWidth: "55%",
              }}
            >
              <Toolbar
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  my: 1,
                }}
              >
                <Grid container spacing={4} my={2}>
                  <Grid item md={12}>
                    {/* Toggle */}
                    <Paper
                      elevation={1}
                      sx={{
                        border: "1px solid #FFFFFF",
                        height: "fit-content",
                        width: "fit-content",
                        display: "flex",
                        backgroundColor: PrimaryColor,
                        flexWrap: "wrap",
                      }}
                    >
                      <ToggleButtonGroup
                        color="primary"
                        sx={{
                          "& .MuiToggleButtonGroup-grouped": {
                            margin: 0.5,
                            border: 0,
                            "&.Mui-selected": {
                              color: "#3E3A3F",
                            },
                          },
                        }}
                        // value="tapeList"
                        exclusive
                        aria-label="Platform"
                        value={listFilter}
                        onChange={(e) => listFilterChange(e)}
                      >
                        <ToggleButton className="display-mode" value="tapeList">
                          Show Tapes
                        </ToggleButton>
                        <ToggleButton className="display-mode" value="itemList">
                          Show Files
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Paper>
                  </Grid>

                  <Grid item md={12}>
                    {/* Toggle */}
                    <Paper
                      elevation={1}
                      sx={{
                        border: "1px solid #FFFFFF",
                        height: "fit-content",
                        width: "fit-content",
                        display: "flex",
                        backgroundColor: PrimaryColor,
                        flexWrap: "wrap",
                      }}
                    >
                      <ToggleButtonGroup
                        color="primary"
                        sx={{
                          "& .MuiToggleButtonGroup-grouped": {
                            margin: 0.5,
                            border: 0,
                            "&.Mui-selected": {
                              color: "#3E3A3F",
                            },
                          },
                        }}
                        value={displayMode}
                        exclusive
                        onChange={handleDisplayModeChange}
                        aria-label="Platform"
                      >
                        <ToggleButton className="display-mode" value="All">
                          Show All
                        </ToggleButton>
                        <ToggleButton className="display-mode" value="Selected">
                          {addedRows.length !== 0 ? addedRows.length : ""}{" "}
                          Selected Only
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Paper>
                  </Grid>
                  <Grid item md={6}>
                    {/* Seach bar */}
                    <InputBase
                      id="search-restores"
                      className="search-restores"
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchQuery}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          sx={{ color: "#A6AEC1", height: 24, width: 24 }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                      }
                      endAdornment={
                        searchQuery !== "" && (
                          <InputAdornment
                            position="end"
                            sx={{ color: "#A6AEC1", height: 24, width: 24 }}
                          >
                            <IconButton
                              onClick={() => { clearSearch() }}
                            >
                              <Cancel sx={{ color: "#FFFFFF" }} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "15px",
                        letterSpacing: "0em",
                        textAlign: "left",
                        width: "100%",
                        height: "60px",
                        backgroundColor: PrimaryColor,
                        borderRadius: "12px",
                        px: "20px",
                        border: "1px solid #C7CCD0",
                        outline: "none",
                        color: "#FFFFFF",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    spacing={4}
                  >
                    {/* Filter Popup */}
                    <Badge badgeContent={filterCount} color="primary">
                      <Button
                        variant="outlined"
                        startIcon={<Tune sx={{ color: "#FFFFFF" }} />}
                        onClick={handleOpenFilterPopup}
                        style={{
                          width: "160px",
                          border: "1px solid #C7CCD0",
                          borderRadius: "12px",
                          color: "#FFFFFF",
                          height: "60px",
                          textTransform: "none",
                          fontWeight: 700,
                        }}
                      >
                        Filters
                      </Button>
                    </Badge>
                    <RestoreFilters
                      open={filterPopupOpen}
                      onClose={handleCloseFilterPopup}
                      fileTypeFilter={fileTypeFilter}
                      setFileTypeFilter={setFileTypeFilter}
                      onFilterChange={(count) => setFilterCount(count)}
                    ></RestoreFilters>
                  </Grid>
                </Grid>
              </Toolbar>

              <Table>
                <TableHead>
                  <TableRow>
                    {
                      listFilter == 'itemList' ?
                        <TableCell className={classes.columnHeader}>
                          <Typography
                            sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                          >
                            File Name
                          </Typography>
                        </TableCell> : ""
                    }
                    <TableCell className={classes.columnHeader}>
                      <Typography
                        sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                      >
                        Tape Volser
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.columnHeader}>
                      <Typography
                        sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                      >
                        {listFilter == 'tapeList' ? 'Tape type' : 'File type'}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.columnHeader}>
                      <Typography
                        sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                      >
                        {listFilter == 'tapeList' ? 'Tape format' : 'File group'}

                      </Typography>
                    </TableCell>
                    <TableCell className={classes.columnHeader}>
                      <Typography
                        sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                      >
                        Data Volume
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.columnHeader}>
                      <Typography
                        sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                      >
                        Retention Date
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.columnHeader}>
                      <Typography
                        sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                      >
                        Created Date
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.columnHeader}>
                      <Typography
                        sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                      >
                        Comments
                      </Typography>
                    </TableCell>
                    {
                      listFilter != 'itemList' ?
                        <TableCell className={classes.columnHeader}>
                          <Typography
                            sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                          >
                            Tape Photograph
                          </Typography>
                        </TableCell> : ""
                    }
                    <TableCell className={classes.columnHeader}>
                      <Typography
                        sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                      >
                        Action
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    "& > *": {
                      backgroundColor: "#3A3E3F",
                      borderRadius: "8px",
                    },
                  }}
                >
                  {restoreData.length > 0 ? (displayMode === "All" ? restoreData : addedRows)
                    // .slice(0, 8)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "& > *": {
                            border: "unset",
                          },
                        }}
                      >
                        {
                          listFilter == 'itemList' ?
                            <TableCell>
                              <Typography
                                className="restore-name"
                                key={index}
                                id={`restore-filename-${index}`}
                                sx={{
                                  color: "#FFFFFF",
                                  fontSize: 14,
                                  display: "flex",
                                  alignItems: "center",
                                  fontFamily: "Poppins",
                                  fontWeight: row.selected ? 700 : 400,
                                  whiteSpace: "pre",
                                }}
                              >
                                <Tooltip title={row?.path}>
                                  {highlightText(row.item_id, row.name)}
                                </Tooltip>
                              </Typography>
                            </TableCell> : ""
                        }
                        <TableCell>
                          <Typography
                            className="restore-name"
                            key={index}
                            id={`restore-name-${index}`}
                            sx={{
                              color: "#FFFFFF",
                              fontSize: 14,
                              display: "flex",
                              alignItems: "center",
                              fontFamily: "Poppins",
                              fontWeight: row.selected ? 700 : 400,
                              whiteSpace: "pre",
                            }}
                          >
                            {highlightText(row.item_id, row.item_volser)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins",
                              fontSize: 14,
                              fontWeight: row.selected ? 700 : 400,
                            }}
                          >
                            {row.media_type}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins",
                              fontSize: 14,
                              fontWeight: row.selected ? 700 : 400,
                            }}
                          >
                            {row.backup_format}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins",
                              fontSize: 14,
                              fontWeight: row.selected ? 700 : 400,
                            }}
                          >
                            {row.size}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins",
                              fontSize: 14,
                              fontWeight: row.selected ? 700 : 400,
                            }}
                          >
                            {row.retentionDate}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins",
                              fontSize: 14,
                              fontWeight: row.selected ? 700 : 400,
                            }}
                          >
                            {row.createdOn}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              color: "#FFFFFF",
                              fontFamily: "Poppins",
                              fontSize: 14,
                              fontWeight: row.selected ? 700 : 400,
                            }}
                          >
                            {row.comments}
                          </Typography>
                        </TableCell>
                        {
                          listFilter != 'itemList' ?
                            <TableCell>
                              <Typography
                                sx={{
                                  color: "#FFFFFF",
                                  fontFamily: "Poppins",
                                  fontSize: 14,
                                  fontWeight: row.selected ? 700 : 400,
                                }}
                              >
                                <img onClick={() => {
                                  selectDetail(row)
                                }} style={{ height: '64px' }} src={getTapeImage(row.media_type)} />
                              </Typography>
                            </TableCell> : ""
                        }
                        <TableCell style={{ textAlign: "center" }}>
                          {row.selected ? (
                            <StyledButtonOutlined
                              variant="outlined"
                              onClick={() => {
                                handleRemoveRow(row.item_id);
                              }}
                              sx={{
                                border: "1px solid #DD2A19",
                                background: "#FFFFFF",
                                color: "#DD2A19",
                                borderRadius: 20,
                                width: 80,
                                height: 40,
                                textTransform: "none",
                              }}
                            >
                              Remove
                            </StyledButtonOutlined>
                          ) : (
                            <StyledButtonOutlined
                              variant="outlined"
                              onClick={() => {
                                handleAddRow(row);
                              }}
                              sx={{
                                border: "1px solid #FFC540",
                                background: "transparent",
                                color: "#FFC540",
                                borderRadius: 20,
                                width: 80,
                                height: 40,
                                textTransform: "none",
                              }}
                            >
                              Add
                            </StyledButtonOutlined>
                          )}
                        </TableCell>
                      </TableRow>
                    )) :
                    <TableRow
                    >
                      <TableCell colSpan={2} align="centre" >
                        <div style={{ textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff" }}>Data not Found</div>

                      </TableCell>
                    </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* Pagination */}
          {displayMode != 'Selected' ?
            <>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  padding={2}
                  style={{
                    width: "100%",
                    border: "1px solid #C7CCD0",
                    minWidth: "55%",
                  }}
                >
                  {/* <Grid item ml={2}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                {`Showing 1-10 of 100 files`}
              </Typography>
            </Grid> */}

                  <Grid item ml={2}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        color: "#FFFFFF",
                      }}
                    >
                      {`Showing ${tapeCount ? startIndex : 0}-${endIndex} of ${tapeCount} Items`}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Pagination
                      component="div"
                      rowsPerPage={10}
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          width: "30px",
                          height: "30px",
                          border: "1px solid #FFFFFF",
                          background: "#3A3E3F",
                          color: "#FFFFFF",
                          margin: "0 4px",
                          "&.Mui-selected": {
                            background: "#FFC540",
                            color: "#3A3E3F",
                          },
                        },
                        "& .MuiPaginationItem-page.Mui-selected:hover": {
                          background: "#FFC540",
                          color: "#3A3E3F",
                        },
                        "& .MuiPaginationItem-page:hover": {
                          background: "#FFC540",
                          color: "#3A3E3F",
                        },
                        "& .MuiPaginationItem-previous, & .MuiPaginationItem-next": {
                          width: "100px",
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          background: "#3A3E3F",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        "& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover":
                        {
                          background: "#FFC540",
                          color: "#3A3E3F",
                        },
                      }}
                      renderItem={(item) => <PaginationItem {...item} />}
                    />
                  </Grid>

                </Grid>
              </Grid></> : ""
          }
        </Grid>

        {/* Bottom Drawer which opens when items selected */}
        <Drawer
          variant={"persistent"}
          anchor={"bottom"}
          open={addedRows.length > 0}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#3A3E3F",
              boxShadow: "0px -7px 11px 0px #00000029",
              height: bottomDrawerHeight,
            },
          }}
        >
          <Box gap={4} display={"flex"} justifyContent={"right"} margin={1}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: 18,
                color: "#FFFFFF",
                fontWeight: 400,
                mt: 2,
              }}
            >
              {addedRows.length !== 0 ? addedRows.length + " file(s) added" : ""}
            </Typography>
            {/* Clear Button */}
            <Button
              variant="outlined"
              onClick={clearAddedRows}
              sx={{
                width: "220px",
                height: "60px",
                borderRadius: "30px",
                color: "#FFFFFF",
                textTransform: "none",
                border: "1px solid #FFFFFF",
                "&:hover": {
                  backgroundColor: "#C7CCD0",
                },
              }}
            >
              Clear
            </Button>
            {/* Checkout Button */}
            <StyledButtonContained
              variant="contained"
              onClick={handleOpenCheckoutDialog}
              sx={{
                width: "220px",
                height: "60px",
              }}
            >
              Checkout
            </StyledButtonContained>
          </Box>
        </Drawer>
        {/*     <RestoreCheckout open={checkoutDialogOpen} onClose={handleCloseCheckoutDialog}></RestoreCheckout> */}
      </ThemeProvider >
      <TapeDetails open={openTapeDetails} closePopup={() => { setOpenTapeDetails(false) }} tapeId={selectedData}></TapeDetails>

    </>
  );
};

const sizes = ["Mb", "Tb", "Pb"];

const RestoreFilters = ({
  open,
  onClose,
  fileTypeFilter,
  setFileTypeFilter,
  onFilterChange,
}) => {
  const handleClose = () => {
    onClose();
  };

  //const [fileTypeFilter, setFileTypeFilter] = useState(() => []);

  const handleFileTypeFilter = (value) => {
    const selectedIndex = fileTypeFilter.indexOf(value);
    let newFileTypeFilter = [...fileTypeFilter];

    if (selectedIndex === -1) {
      newFileTypeFilter.push(value);
    } else {
      newFileTypeFilter.splice(selectedIndex, 1);
    }

    setFileTypeFilter(newFileTypeFilter);
  };

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const [minSize, setMinSize] = useState("");
  const [maxSize, setMaxSize] = useState("");

  useEffect(() => {
    let filterCount = fileTypeFilter.length;
    filterCount += startDateFilter && endDateFilter ? 1 : 0;
    filterCount += minSize && maxSize ? 1 : 0;

    onFilterChange(filterCount);
  }, [startDateFilter, endDateFilter, minSize, maxSize, fileTypeFilter.length]);

  const handleFilterClear = () => {
    setFileTypeFilter([]);
    setEndDateFilter(null);
    setStartDateFilter(null);
    setMaxSize("");
    setMinSize("");
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          top: "25%",
          borderRadius: 2,
          p: 2,
          width: 400,
          height: "70%",
          overflowY: "auto",
          backgroundColor: "#4E5152",
          boxShadow: "-4px 4px 4px 0px #00000029",
        },
      }}
    >
      <Grid container px={2} spacing={2} style={{ "marginBottom": '140px' }}>
        <Grid item xs={12}>
          {/* Title area */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ color: "#FFFFFF" }} noWrap>
              Filters
            </Typography>
            <IconButton onClick={handleClose}>
              <Close sx={{ color: "#FFFFFF" }} />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {/* File type filters */}
          <Typography sx={{ color: "#FFFFFF", textAlign: "left" }}>
            File types:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {docTypeOverview.map((type) => (
              <Grid item>
                <ToggleButton
                  className="file-type"
                  value={type.docType}
                  selected={fileTypeFilter.includes(type.docType)}
                  onClick={() => handleFileTypeFilter(type.docType)}
                  variant="outlined"
                >
                  {type.docType}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {/* Size filters */}
          <Typography sx={{ color: "#FFFFFF", textAlign: "left" }}>
            Size:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack direction={"row"}>
            <TextField
              className="number-input"
              variant="outlined"
              label="min-size"
              value={minSize}
              onChange={(e) => setMinSize(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              select
              variant="outlined"
              value="Mb"
              className="number-input-uom"
            >
              {sizes.map((size, sizeIndex) => (
                <MenuItem key={sizeIndex} value={size}>
                  {size}
                </MenuItem>
              ))}
            </TextField>
            <Typography
              px={2}
              sx={{
                color: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              -
            </Typography>
            <TextField
              className="number-input"
              variant="outlined"
              label="max-size"
              value={maxSize}
              onChange={(e) => setMaxSize(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              select
              variant="outlined"
              value="Mb"
              className="number-input-uom"
            >
              {sizes.map((size, sizeIndex) => (
                <MenuItem key={sizeIndex} value={size}>
                  {size}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          {/* Date filters */}
          <Typography sx={{ color: "#FFFFFF", textAlign: "left" }}>
            Creation Date:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack direction={"row"} gap={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Start Date"
                disableFuture
                className="date-input"
                type="date"
                value={startDateFilter}
                onChange={(newValue) => setStartDateFilter(newValue)}
                variant="outlined"
              />
              <DatePicker
                label="End Date"
                disableFuture
                className="date-input"
                type="date"
                value={endDateFilter}
                onChange={(newValue) => setEndDateFilter(newValue)}
                variant="outlined"
              />
            </LocalizationProvider>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Divider
            orientation="horizontal"
            sx={{ backgroundColor: "#FFFFFF", width: "100%", my: 2 }}
          ></Divider>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {/* Clear Button */}
            <StyledButtonContained
              onClick={handleFilterClear}
              variant="contained"
              size="medium"
              color="secondary"
            >
              Clear
            </StyledButtonContained>
            {/* Apply Button */}
            <StyledButtonContained
              variant="contained"
              size="medium"
              color="primary"
            >
              Apply
            </StyledButtonContained>
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
};
