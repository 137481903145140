import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  InputAdornment,
  Select,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import { CheckCircle, Error } from "@mui/icons-material";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import {
  StyledButtonContained,
  StyledButtonOutlined,
} from "../Admin/AccountCreation/accountCreation";
import { getLoginUser } from "../../LocalStorageService";
import axios from "axios";
import { getApiUrl } from "./../../global";

const RestoreForm = ({
  isFormOpen,
  handleCloseFormDialog,
  cloudShow,
  physicalShow,
  rowData,
  handleCloseCheckoutDialog,
  handleClickcancel,
  clearAddedRows,
  showReset,
  listFilter
}) => {
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { vertical, horizontal, open } = state;
  const { register, handleSubmit } = useForm();
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [urgencyLevel, seturgencyLevel] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [tapeIds, setTapeIds] = useState("");
  const [fileName, setFileName] = useState("");
  const [method, setMethod] = useState([]);
  const userDetails = getLoginUser();
  const openSnackbar = (message) => {
    setSnackbarMessage(message);
    setState({
      vertical: "top",
      horizontal: "right",
      open: true,
    });
  };

  const closeSnackbar = () => {
    setState({
      vertical: "top",
      horizontal: "right",
      open: false,
    });
  };
  // Validators function
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const validatePhoneNumber = (phone) => {
    const formattedPhoneNumber = phone.replace(/\D/g, "");
    if (formattedPhoneNumber.length < 10) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };
  const useStyles = makeStyles({
    selectLabel: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          borderColor: "#C7CCD0",
        },
        "&:hover fieldset": {
          borderColor: "#C7CCD0",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#C7CCD0",
        },
      },
      "& .MuiInputBase-root.Mui-disabled": {
        "& > fieldset": {
          color: "rgba(255, 255, 255, 0.5)",
          borderColor: "#C7CCD0",
        }
      },
      "& .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "& .MuiSelect-root": {
        borderRadius: "8px",
        "& > fieldset": {
          color: "rgba(255, 255, 255, 0.5)",
	},
        "& .Mui-disabled": {
          color: "rgba(255, 255, 255, 0.5)",
	},
        "& .Mui-input": {
          color: "rgba(255, 255, 255, 0.5)",
	},
      },
      "& .MuiInputLabel-root": {
        color: "rgba(255, 255, 255, 0.5)",
        "&.Mui-focused": {
          color: "#FFC540",
        },
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.5)",
	},
      },
      "& svg": {
        color: "#FFFFFF",
      },
    },
  });
  const classes = useStyles();

  const onSubmit = async (data) => {
    console.log(data);
    console.log("Form Submitted");
    let url = await getApiUrl();
    let apiUrl =
      url + process.env.REACT_APP_API_BASE_URL + `/contact/`;
    let payload = {
      company_name: data.companyName,
      contact_name: contactName,
      email: email,
      phone_number: phoneNumber,
      contact_type: "restore",
      message: message,
      urgency: urgencyLevel,
      tape_ids: tapeIds,
      file_names: listFilter == 'itemList' ? fileName : undefined,
      method: method.map((i) => `${i}`).join(","),
      contact_by_phone: false,
      contact_by_email: false,
      contact_email: email,
    };
    axios
      .post(apiUrl, payload)
      .then((response) => {
        if (response?.status == 201) {
          openSnackbar("E-mail sent to Admin to create Restore.");
          handleCloseFormDialog();

          handleClickcancel();
          handleCloseCheckoutDialog();
          clearAddedRows();
          showReset()
        }
      })
      .catch((error) => {
        console.log(error);
        setSnackbarMessage(error);
      });
  };

  useEffect(() => {
    console.log({ userDetails });
    setCompanyName(
      userDetails.user.company_name
    );
    setContactName(
      userDetails.user.first_name + " " + userDetails.user.last_name
    );
    setEmail(userDetails.user.email);
    const rowIds = rowData?.map((i) => i.item_volser).join(",");
    setTapeIds(rowIds);

    if (listFilter == 'itemList') {
      const rowIds = rowData?.map((i) => i.name).join(",");
      setFileName(rowIds);
    }
    if (physicalShow) {
      setMethod(["physicalRestore"]);
    }
    if (cloudShow) {
      setMethod(["cloudRestore"]);
    }
    if (cloudShow && physicalShow) {
      setMethod(["cloudRestore", "physicalRestore"]);
    }
    // if (cloudShow && physicalShow) {
    // setMethod(["cloudRestore", "physicalRestore"]);
    // else if(cloud)
    // }
  }, [physicalShow, cloudShow, rowData]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={closeSnackbar}
        message={snackbarMessage}
        key={vertical + horizontal}
      />
      <div>
        <Dialog
          open={isFormOpen}
          maxWidth="md"
          fullWidth={true}
          PaperProps={{
            sx: { borderRadius: "12px", backgroundColor: "#3A3E3F" },
          }}
        >
          <DialogContent
            sx={{ p: 4, backgroundColor: "#3A3E3F", color: "#FFFFFF" }}
          >
            {/* <Grid container direction='column' spacing={2}> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "#FFC540", fontSize: 18 }}>
                Restore Process
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl {...register("companyName")} fullWidth>
                    <TextField
                      label="Company Name"
                      name="companyName"
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      disabled
                      InputProps={{
                        endAdornment: companyName !== "" && (
                          <InputAdornment position="end">
                            <CheckCircle sx={{ color: "#009621" }} />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                    />{" "}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl {...register("contactName")} fullWidth>
                    <TextField
                      label="Contact Name"
                      name="contactName"
                      value={contactName}
                      onChange={(e) => setContactName(e.target.value)}
                      required
                      InputProps={{
                        endAdornment: contactName !== "" && (
                          <InputAdornment position="end">
                            <CheckCircle sx={{ color: "#009621" }} />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl {...register("emailAddress")} fullWidth>
                    <TextField
                      label="Email Address"
                      name="emailAddress"
                      type="email"
                      onBlur={(e) => {
                        if (e.target.value.length > 0) {
                          validateEmail(e.target.value);
                        }
                      }}
                      error={emailError}
                      required
                      value={email}
                      InputProps={{
                        endAdornment: emailError !== null &&
                          email !== "" && (
                            <InputAdornment position="end">
                              {emailError ? (
                                <Error color="error" />
                              ) : (
                                <CheckCircle sx={{ color: "#009621" }} />
                              )}
                            </InputAdornment>
                          ),
                      }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (emailError) {
                          setEmailError(null);
                        }
                      }}
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      helperText={
                        emailError &&
                        "Invalid Email. It should be in the format example@domain.com"
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl {...register("phoneNumber")} fullWidth>
                    <TextField
                      label="Phone Number"
                      name="phoneNumber"
                      // type="tel"
                      value={phoneNumber}
                      required
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        if (phoneNumberError) {
                          setPhoneNumberError(null);
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length > 0) {
                          validatePhoneNumber(e.target.value);
                        }
                      }}
                      error={phoneNumberError}
                      InputProps={{
                        endAdornment: phoneNumberError !== null &&
                          phoneNumber !== "" && (
                            <InputAdornment position="end">
                              {phoneNumberError ? (
                                <Error color="error" />
                              ) : (
                                <CheckCircle sx={{ color: "#009621" }} />
                              )}
                            </InputAdornment>
                          ),
                      }}
                      helperText={
                        phoneNumberError &&
                        "Invalid Phone. It should be 10 digits"
                      }
                    />
                  </FormControl>
                </Grid>
                {listFilter == 'itemList' ?
                  <Grid item xs={12}>
                    <FormControl {...register("fileName")} fullWidth>
                      <TextField
                        label="File Names"
                        name="fileName"
                        disabled
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                        required
                        InputProps={{
                          endAdornment: fileName !== "" && (
                            <InputAdornment position="end">
                              <CheckCircle sx={{ color: "#009621" }} />
                            </InputAdornment>
                          ),
                        }}
                        className={classes.selectLabel}
                        style={{ marginBottom: "12px" }}
                      />
                    </FormControl>
                  </Grid> : ""
                }
                <Grid item xs={12}>
                  <FormControl {...register("tapIds")} fullWidth>
                    <TextField
                      label="Tape Volsers"
                      name="tapIds"
                      disabled
                      value={tapeIds}
                      onChange={(e) => setTapeIds(e.target.value)}
                      required
                      InputProps={{
                        endAdornment: tapeIds !== "" && (
                          <InputAdornment position="end">
                            <CheckCircle sx={{ color: "#009621" }} />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl {...register("fileName")} fullWidth>
	  	    <Tooltip title="Add instructions for the restore here. This can include dates, servers etc">
                      <TextField
                        label="User Notes"
                        name="fileName"
                        type="text"
                        multiline
                        onChange={(e) => setMessage(e.target.value)}
                        inputProps={{ maxLength: 1200 }}
                        rows={4}
                        className={classes.selectLabel}
                        style={{ marginBottom: "12px" }}
                      />
	            </Tooltip>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl {...register("method")} fullWidth>
                    <Select
                      label="Restore Method"
                      multiple
                      // disabled // making this undisabled changes the text color to black
                      value={method}
                      className={classes.selectLabel}
                      style={{ marginBottom: "12px", borderRadius: "8px", border: '1px solid #C7CCD0' }}
	  	      sx={{
		     	color: '#FFFFFF !important' 
		      }}
                    >
                      <MenuItem value="physicalRestore" sx={{color: '#FFFFFF !important'}}>
                        Physical Restore
                      </MenuItem>
                      <MenuItem value="cloudRestore" sx={{color: '#FFFFFF !important'}}>
                        Cloud Restore
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl {...register("urgencyLevel")} fullWidth>
	  	    <Tooltip title="Note: Additional charges apply for higher priority restores.">
                      <TextField
                        label="Urgency Level"
                        name="urgencyLevel"
                        className={classes.selectLabel}
                        style={{ marginBottom: "12px" }}
                        value={urgencyLevel}
                        onChange={(e) => seturgencyLevel(e.target.value)}
                        select
                      >
                        <MenuItem value="high">High</MenuItem>
                        <MenuItem value="medium">Medium</MenuItem>
                        <MenuItem value="low">Low</MenuItem>
                      </TextField>
	  	    </Tooltip>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <StyledButtonOutlined
                      variant="outlined"
                      onClick={handleCloseFormDialog}
                      sx={{
                        width: "200px",
                        height: "60px",
                      }}
                    >
                      Cancel
                    </StyledButtonOutlined>
                  </Grid>
                  <StyledButtonContained
                    variant="contained"
                    type="submit"
                    disabled={
                      phoneNumberError ||
                      phoneNumber === "" ||
                      emailError ||
                      email === ""
                    }
                    sx={{
                      width: "200px",
                      height: "60px",
                      borderRadius: "30px",
                    }}
                  >
                    Submit
                  </StyledButtonContained>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default RestoreForm;
